
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































































.cta-experience {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  min-height: 40rem;

  @include mq(xs) {
    min-height: 46rem;
  }

  @include mq(s) {
    min-height: unset;
  }
}

.cta-experience-wrapped {
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: $c-mustard;
  }

  @include mq(s) {
    position: relative;
    padding: 8rem 8rem 8rem 0;

    &::after {
      top: 0;
      right: 70%;
      bottom: unset;
      left: unset;
      // width: calc(calc(calc(100vw - 100%) / 2) + 30%);
      width: calc(50vw - 20%);
      height: 100%;
      transform: none;
    }
  }

  @include mq(l) {
    padding-left: col(1, 10);

    .homepage & {
      padding-left: 0;
    }
  }
}

.cta-experience__content {
  width: auto;
  max-width: none;
  margin: 70% 0 $spacing * 2.5 0;
  padding: $spacing;
  background: $c-white;
  border-radius: 4px;

  @include mq(xs) {
    width: calc(100% - calc(#{$spacing} * 4));
    min-width: min-content;
    max-width: 32rem;
    margin: $spacing * 2 auto;
    padding: $spacing * 1.5;
  }

  @include mq(s) {
    width: col(5, 10);
    min-width: min-content;
    margin: 0;
    padding: 4.5rem;
  }

  @include mq(l) {
    padding: 6rem;
  }
}

.cta-experience__content__icon {
  color: $c-mustard;
}

.cta-experience__content__title {
  min-width: 7em;
  line-height: 1.2;

  ::v-deep em {
    color: $c-dark-grey;
  }
}

.cta-experience__content__link {
  margin-top: $spacing * 1.5;

  &::after {
    @include get-all-space;

    content: '';
  }

  @include mq(s) {
    @include default-margin(0.5);
  }
}

.cta-experience__content__link__icon {
  fill: $c-mustard;
}

.cta-experience__picture {
  @include get-all-space;

  z-index: -1;
  overflow: hidden;
  height: 70%;
  transition: 0.5s $ease-out-quad;
  transition-property: transform;

  ::v-deep img {
    transition: transform 0.3s $ease-softer;

    .cta-experience:hover & {
      transform: scale(1.1);
    }
  }

  @include mq(s) {
    left: 30%;
    width: calc(calc(calc(100vw - 100%) / 2) + 70%);
    height: 100%;
  }
}

.cta-experience__picture__logo {
  @include center-xy;

  z-index: 9;
  padding: $spacing;
  background-color: $c-mustard;
  border-radius: 10rem;

  .icon {
    fill: $c-white;
  }

  @include mq($until: l) {
    top: 30%;
  }

  @include mq(l) {
    .icon {
      width: 8rem;
      height: auto;
    }
  }
}
